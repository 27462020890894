import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    h2: "h2",
    pre: "pre",
    ..._provideComponents(),
    ...props.components
  }, {Disclosure} = _components;
  if (!Disclosure) _missingMdxReference("Disclosure", true);
  if (!Disclosure.Button) _missingMdxReference("Disclosure.Button", true);
  if (!Disclosure.Panel) _missingMdxReference("Disclosure.Panel", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "import { Disclosure } from \"clutch/src/Accordion/Accordion.jsx\";\n"
      })
    }), "\n", _jsx(_components.h2, {
      id: "disclosure",
      children: _jsx(_components.a, {
        href: "#disclosure",
        children: "Disclosure"
      })
    }), "\n", _jsx(_components.code, {
      preview: true,
      className: "language-jsx",
      code: `<Disclosure>
  <Disclosure.Button>Who can participate in the event?</Disclosure.Button>
  <Disclosure.Panel>
    This event is only available for players in the United States.
  </Disclosure.Panel>
</Disclosure>`,
      children: _jsxs(Disclosure, {
        children: [_jsx(Disclosure.Button, {
          children: "Who can participate in the event?"
        }), _jsx(Disclosure.Panel, {
          children: "This event is only available for players in the United States."
        })]
      })
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
